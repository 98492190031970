<template>
  <div>
    <Navbar :isShare="isShare" :isShowSearchInput="isShowSearchInput">
      <SearchInput
        :searchValue="searchNameList"
        @search="loadData('refresh')"
        @changeSearch="changeSearch"
        slot="searchInput"
      />
    </Navbar>
    <v-main>
      <BaseCardList :itemList="detailsList" />
      <scroll-loader
        :loader-method="loading"
        :loader-disable="disable"
        loader-color="#D60000"
      >
      </scroll-loader>
      <BaseLoading :searchList="searchList" />
    </v-main>
  </div>
</template>

<script>
import SearchInput from '@/components/Main/Search/Search'
import SearchServices from '@/services/SearchServices'
import BaseLoading from '@/components/Main/BaseLoading'

export default {
  name: 'SearchList',
  components: {
    BaseLoading,
    SearchInput,
  },
  props: {
    searchName: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isShare: true,
      searchNameList: '',
      loadingType: 'loading',
      detailsList: [],
      disable: false,
      page: 1,
      pageSize: 10,
      totalItems: 0,
      isShowSearchInput: true,
      searchList: false,
    }
  },
  created() {
    this.searchNameList = this.searchName || ''
    this.loadData('refresh')
  },
  methods: {
    loadData(type) {
      setTimeout(() => {
        this.loadDataAsync(type)
      }, 200)
    },
    async loadDataAsync(type) {
      if (type === 'add') {
        if (this.loadingType === 'nomore') {
          return
        }
      }

      if (type === 'refresh') {
        this.detailsList = []
        this.page = 1
        this.totalItems = 0
        this.disable = false
        this.searchList = false
      }

      SearchServices.getSearch({
        keyword: this.searchNameList,
        page: this.page,
        limit: this.pageSize,
      })
        .then(resp => {
          if (resp.status === 200) {
            this.page++
            this.detailsList = resp.data.items || []
            this.totalItems = resp.data.meta.totalItems
            if (this.detailsList.length === this.totalItems) {
              this.disable = true
            }

            if (this.detailsList.length === 0) {
              this.searchList = true
            }
          }
        })
        .catch(err => {
          this.$swal
            .fire({
              icon: 'error',
              title: `${err.message}`,
              showDenyButton: false,
            })
            .then(result => {
              if (result.isConfirmed) {
                this.$router.push({ name: 'News' })
              }
            })
        })
      this.loadingType = 'loading'
    },
    changeSearch(val) {
      this.searchNameList = val
    },
    loading() {
      if (this.totalItems > 0) {
        SearchServices.getSearch({
          keyword: this.searchNameList,
          page: this.page,
          limit: this.pageSize,
        })
          .then(res => {
            if (res.status === 200) {
              this.page++
              this.detailsList.push(...res.data.items)
              this.totalItems = res.data.meta.totalItems

              // Stop scroll loading...
              if (this.detailsList.length === this.totalItems) {
                this.disable = true
              }

              if (this.detailsList.length === 0) {
                this.searchList = true
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
  },
}
</script>

<style scoped></style>
