import { apiServices } from '@/utils/request'

export default {
  getSearchHistory() {
    return apiServices.get('/searchHistory')
  },
  getSearch({ keyword, page, limit }) {
    return apiServices.post(
      `/contents/searchContent?keyword=${keyword}&page=${page}&limit=${limit}`,
    )
  },
}
