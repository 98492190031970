<template>
  <v-text-field
    ref="valid"
    :label="$i18n.locale === 'th_TH' ? $t(`search.title`) : $t(`search.title`)"
    solo
    rounded
    color="#DA1F28"
    @input="changeSearch"
    @keydown.enter="search"
    clearable
    v-model="searchInput"
    prepend-inner-icon="mdi-magnify"
    flat
    hide-details
    counter
    autofocus
    class="search"
    maxlength="20"
  >
  </v-text-field>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Search',
  props: {
    searchValue: {
      default: '',
    },
  },
  data() {
    return {
      searchInput: this.searchValue,
    }
  },
  methods: {
    ...mapMutations({
      getSearchHistory: 'SearchHistoryStore/getSearchHistory',
    }),
    search() {
      if (this.searchInput !== '') {
        this.getSearchHistory(this.searchInput)
      }

      this.$emit('search')
    },
    changeSearch(val) {
      this.$emit('changeSearch', val)
    },
  },
}
</script>

<style>
.search.v-text-field.v-text-field--solo .v-input__control {
  min-height: 34px;
}
</style>
